<template>
    <div>
        <loader :loading="loading" :backdrop="true" />
        <div class="flex">
            <div class="w-1/2 pr-4">
                <p>header embeds</p>
                <textarea v-model="header_embed" class="w-full h-64 border p-2 text-sm focus:outline-none"></textarea>
            </div>
            <div class="w-1/2 pl-4">
                <p>body embeds</p>
                <textarea v-model="footer_embed" class="w-full h-64 border p-2 text-sm focus:outline-none"></textarea>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/ui/Loader";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
    mixins: [ ModalNavigation ],
    components: { Loader, ModalFooter },
    data: () => {
        return {
            website: null,
            header_embed: null,
            footer_embed: null,
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            getWebsiteInfo: 'website_info/getWebsiteInfo'
        }),
    },
    methods: {
        ...mapActions({
            updateWebsiteInStore: 'websites/updateWebsite',
            clearWebsiteInformation: 'website_info/clearWebsiteInformation',
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
            addWebsiteInfo: 'website_info/addWebsiteInfo',
        }),
        save() {
            this.loading = true;

            let payload = {
                customers_id : this.website.customers_id,
                communities_id : this.website.communities_id,
                custom_links: this.website.custom_links,
                header_embed: this.header_embed,
                footer_embed: this.footer_embed
            }

            this.$cmsDataProvider.update('updateWebsite', { data: payload, id: this.website.id })
                .then(() => {
                    this.loading = false;
                    this.notifySuccess('The embed codes were saved successfully');
                    this.redirect('websites.show', this.website.id);
                    this.clearWebsiteInformation();
                })
                .catch(() => {
                    this.loading = false;
                    this.notifyError('There was an error saving the embed codes');
                });
            this.ReloadWebsiteInfo();
        },
        async ReloadWebsiteInfo() {
            this.clearWebsiteInformation();
            await this.$cmsDataProvider.get('websitebyId', {websiteId: this.$route.params.id})
            .then((response) => {
                if(!response.message) {
                    this.website = response
                    this.addWebsiteInfo(response);
                }else{
                    this.notifyError('Website not found');
                }
            }).catch(() => {
                this.notifyError('There was an error getting the website ');
                this.website = null
            });
        },
    },
    async mounted() {
        this.website = this.getWebsiteInfo
        this.header_embed = this.website.header_embed;
        this.footer_embed = this.website.footer_embed;
    },
}
</script>